import React from "react";
import LightBtn from "../../../UI/LightBtn/LightBtn";
import classes from "./BestTerms.module.scss";
import { useTranslation } from "react-i18next";
import icon1 from "../../../assets/img/Main/icon-term-1.png"
import icon2 from "../../../assets/img/Main/icon-term-2.png"
import icon3 from "../../../assets/img/Main/icon-term-3.png"
import icon4 from "../../../assets/img/Main/icon-term-4.png"
import icon5 from "../../../assets/img/Main/icon-term-5.png"
import icon6 from "../../../assets/img/Main/icon-term-6.png"

const BestTerms = () => {
  const { t } = useTranslation();
  return (
    <div className="container">
      <div className={classes.bestTermsWrap}>
        <h3 className={classes.bestTermsTitle}>{t("main_three__title")}</h3>
        <div className={classes.bestTermsRow}>
          <div className={classes.col2}>
            <div className={classes.bestTermsItem}>
              <div className={classes.imgWrap}>
                <img src={icon1} className={classes.imgFluid} alt=""/>
              </div>
              <h6 className={classes.bestTermsItemTitle}>
                {t("main_three_item1__title")}
              </h6>
              <p className={classes.bestTermsItemText}>
                {t("main_three_item1__subtitle")}
              </p>
            </div>
          </div>
          <div className={classes.col2}>
            <div className={classes.bestTermsItem}>
              <div className={classes.imgWrap}>
                <img src={icon2} className={classes.imgFluid} alt=""/>
              </div>
              <h6 className={classes.bestTermsItemTitle}>
                {t("main_three_item2__title")}
              </h6>
              <p className={classes.bestTermsItemText}>
                {t("main_three_item2__subtitle")}
              </p>
            </div>
          </div>
          <div className={classes.col2}>
            <div className={classes.bestTermsItem}>
              <div className={classes.imgWrap}>
                <img src={icon3} className={classes.imgFluid} alt=""/>
              </div>
              <h6 className={classes.bestTermsItemTitle}>
                {t("main_three_item3__title")}
              </h6>
              <p className={classes.bestTermsItemText}>
                {t("main_three_item3__subtitle")}
              </p>
            </div>
          </div>
          <div className={classes.col2}>
            <div className={classes.bestTermsItem}>
              <div className={classes.imgWrap}>
                <img src={icon4} className={classes.imgFluid} alt=""/>
              </div>
              <h6 className={classes.bestTermsItemTitle}>
                {t("main_three_item4__title")}
              </h6>
              <p className={classes.bestTermsItemText}>
                {t("main_three_item4__subtitle")}
              </p>
            </div>
          </div>
          <div className={classes.col2}>
            <div className={classes.bestTermsItem}>
              <div className={classes.imgWrap}>
                <img src={icon5} className={classes.imgFluid} alt=""/>
              </div>
              <h6 className={classes.bestTermsItemTitle}>
                {t("main_three_item5__title")}
              </h6>
              <p className={classes.bestTermsItemText}>
                {t("main_three_item5__subtitle")}
              </p>
            </div>
          </div>
          <div className={classes.col2}>
            <div className={classes.bestTermsItem}>
              <div className={classes.imgWrap}>
                <img src={icon6} className={classes.imgFluid} alt=""/>
              </div>
              <h6 className={classes.bestTermsItemTitle}>
                {t("main_three_item6__title")}
              </h6>
              <p className={classes.bestTermsItemText}>
                {t("main_three_item6__subtitle")}
              </p>
            </div>
          </div>
        </div>
        <div className={classes.bannerTermsLinkWrap}>
          <LightBtn />
        </div>
      </div>
    </div>
  );
};

export default BestTerms;
