import React, { useState } from "react";
import About from "../../components/AboutPage/About/About";
import Forex from "../../components/AboutPage/Forex/Forex";
import Spoilers from "../../components/AboutPage/Spoilers/Spoilers";
import Why from "../../components/AboutPage/Why/Why";
import Footer from "../../components/GlobalComponents/Footer/Footer";
import Header from "../../components/GlobalComponents/Header/Header";

import classes from "./AboutPage.module.scss";
const AboutPage = () => {
  const [why, setWhy] = useState(false);
  const [spoilers, setSpoilers] = useState(false);
  const [forex, setForex] = useState(false);

  return (
    <div
      className={classes.about}
      onScroll={() => {
        if (document.documentElement.clientWidth > 1220) {
          if (
            document.querySelector("#about1").getBoundingClientRect().top < 450
          ) {
            setWhy(true);
          } else {
            setWhy(false);
          }

          if (
            document.querySelector("#about2").getBoundingClientRect().top < 600
          ) {
            setSpoilers(true);
          } else {
            setSpoilers(false);
          }
          if (
            document.querySelector("#about3").getBoundingClientRect().top < 900
          ) {
            setForex(true);
          } else {
            setForex(false);
          }
        }
      }}
    >
      <Header />
      <About />
      <div id="about1">
        <Why why={why} />
      </div>

      <div id="about2">
        <Spoilers spoilers={spoilers} />
      </div>

      <div id="about3">
        <Forex forex={forex} />
      </div>
      <Footer />
    </div>
  );
};

export default AboutPage;
