import React from "react";
import LightBtn from "../../../UI/LightBtn/LightBtn";
import classes from "./Click.module.scss";
import img1 from "../../../assets/img/education/5/1.png";
import { useTranslation } from "react-i18next";
const Click = (props) => {
  const { t } = useTranslation();

  return (
    <div
      className={
        props.click ? [classes.click, classes.hover].join(" ") : classes.click
      }
    >
      <div className={classes.clickBody}>
        <div className="container">
          <div className={classes.clickRow}>
            <div
              className={[
                classes.clickRowClick,
                `clickRowClick_${t("lang")}`,
              ].join(" ")}
            >
              {t("education_five__item1")}
            </div>
            <div className={classes.clickRowArrow}>
              <img src={img1} alt="" />
            </div>
            <div className={classes.clickRowCenterText}>
              {t("education_five__item2")}
            </div>
            <div className={classes.clickRowArrow}>
              <img src={img1} alt="" />
            </div>
            <div className={classes.clickRowRightText}>
              {t("education_five__item3")}
            </div>
          </div>
          <div className={classes.clickStart}>
            <LightBtn />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Click;
