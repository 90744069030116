import React, { useState } from "react";
import img from "../../../assets/img/faq/1/1.png";
import classes from "./Support.module.scss";
import img1 from "../../../assets/img/faq/1/figures/1.png";
import img2 from "../../../assets/img/faq/1/figures/2.png";
import img3 from "../../../assets/img/faq/1/figures/3.png";
import img4 from "../../../assets/img/faq/1/figures/4.png";
import img5 from "../../../assets/img/faq/1/figures/5.png";
import img6 from "../../../assets/img/faq/1/figures/6.png";
import img7 from "../../../assets/img/faq/1/figures/7.png";
import Spoiler from "../../../UI/Spoiler/Spoiler";
import LightBtn from "../../../UI/LightBtn/LightBtn";
import { useTranslation } from "react-i18next";
const Support = () => {
  const { t } = useTranslation();
  const [spoilers, setSpoilers] = useState([
    {
      id: 1,
      title: "faq_tab1__title",
      text: "faq_tab1__text",
      active: false,
    },
    {
      id: 2,
      title: "faq_tab2__title",
      text: "faq_tab2__text",
      active: false,
    },
    {
      id: 3,
      title: "faq_tab3__title",
      text: "faq_tab3__text",
      active: false,
    },
    {
      id: 4,
      title: "faq_tab4__title",
      text: "faq_tab4__text",
      active: false,
    },
  ]);

  return (
    <div className={classes.support}>
      <div className={classes.supportBody}>
        <div className="container">
          <div className={classes.supportWrapper}>
            <div className={classes.supportTitle}>
              <img src={img} alt="" />
              {t("faq__title")}
            </div>
            {spoilers.map((i, index) => {
              return (
                <Spoiler
                  {...i}
                  key={index}
                  clickEvent={() => {
                    setSpoilers(
                      spoilers.map((spoilerI) => {
                        if (spoilerI.id === i.id) {
                          return { ...spoilerI, active: !i.active };
                        }
                        return { ...spoilerI, active: false };
                      })
                    );
                  }}
                />
              );
            })}
            <div className={classes.supportStart}>
              <LightBtn />
            </div>
            <div className={classes.supportIcon1}>
              <img src={img1} alt="" />
            </div>
            <div className={classes.supportIcon2}>
              <img src={img2} alt="" />
            </div>
            <div className={classes.supportIcon3}>
              <img src={img3} alt="" />
            </div>
            <div className={classes.supportIcon4}>
              <img src={img4} alt="" />
            </div>
            <div className={classes.supportIcon5}>
              <img src={img5} alt="" />
            </div>
            <div className={classes.supportIcon6}>
              <img src={img6} alt="" />
            </div>
            <div className={classes.supportIcon7}>
              <img src={img7} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Support;
