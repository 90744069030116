import React from "react";
import classes from "./AllMarkets.module.scss";
import LightBtn from "../../../UI/LightBtn/LightBtn";
import img from "../../../assets/img/Main/all-markets-img.png";
import { useTranslation } from "react-i18next";
const AllMarkets = () => {
  const { t } = useTranslation();
  return (
    <div className="container">
      <div className={classes.allMarketsRow}>
        <div className={classes.allMarketsCol6}>
          <h2 className={classes.allMarketsTitle}>{t("main_nine__title")}</h2>
          <p className={classes.allMarketText}>{t("main_nine__subtitle1")}</p>
          <p className={classes.allMarketText}>{t("main_nine__subtitle2")}</p>
          <div className={classes.allMarketsList}>
            <div className={classes.allMarketsItem}>
              <p className={classes.allMarketsItemText}>
                {t("main_nine__item1")}
              </p>
            </div>
            <div className={classes.allMarketsItem}>
              <p className={classes.allMarketsItemText}>
                {t("main_nine__item2")}
              </p>
            </div>
            <div className={classes.allMarketsItem}>
              <p className={classes.allMarketsItemText}>
                {t("main_nine__item3")}
              </p>
            </div>
            <div className={classes.allMarketsItem}>
              <p className={classes.allMarketsItemText}>
                {t("main_nine__item4")}
              </p>
            </div>
            <div className={classes.allMarketsItem}>
              <p className={classes.allMarketsItemText}>
                {t("main_nine__item5")}
              </p>
            </div>
            <div className={classes.allMarketsItem}>
              <p className={classes.allMarketsItemText}>
                {t("main_nine__item6")}
              </p>
            </div>
          </div>
          <div
            className={[
              classes.allMarketsCol6,
              classes.allMarketsImgWrapMobile,
            ].join(" ")}
          >
            <img src={img} alt="" className={classes.imgFluid} />
          </div>
          <div className={classes.allMarketsLinkWrap}>
            <LightBtn />
          </div>
        </div>
        <div
          className={[classes.allMarketsCol6, classes.allMarketsImgWrap].join(
            " "
          )}
        >
          <img src={img} alt="" className={classes.imgFluid} />
        </div>
      </div>
    </div>
  );
};

export default AllMarkets;
