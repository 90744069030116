import React from "react";
import classes from './status.module.scss';
import image from '../assets/img/success.svg';

const SuccessPage = () => {
    return (
        <div className={classes.wrap}>
            <div className={classes.content}>
                <img className={classes.image} src={image} alt='' />
                <p className="container">
                    Payment was<br/><b>successful</b>
                </p>
            </div>
        </div>
    );
};

export default SuccessPage;
