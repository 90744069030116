import React, { useState } from "react";
import classes from "./Banner.module.scss";
import cow from "../../../assets/img/QuickStart/banner_cow.png";
import cow_mob from "../../../assets/img/QuickStart/banner_cow_mob.png";
import polygon from "../../../assets/img/QuickStart/item_polygon.png";
import sphere from "../../../assets/img/QuickStart/item_sphere.png";
import hemisphere from "../../../assets/img/QuickStart/item_hemisphere.png";
import bagel_half from "../../../assets/img/QuickStart/item_bagel_half.png";
import LightBtn from "../../../UI/LightBtn/LightBtn";
import { Trans, useTranslation } from "react-i18next";
const Banner = () => {
  const { t } = useTranslation();
  const [active, setActive] = useState(false);
  return (
    <div className="container">
      <div className={classes.banner}>
        <div id="content" className={classes.content}>
          <div className={classes.header}>
            <Trans>{t("quickstart_first__title")}</Trans>
          </div>
          <div className={classes.text}>{t("quickstart_first__subtitle")}</div>
          <div
            className={classes.btn}
            onMouseEnter={() => window.innerWidth > 1220 && setActive(true)}
            onMouseLeave={() => window.innerWidth > 1220 && setActive(false)}
          >
            <LightBtn />
          </div>
        </div>
        <div
          className={
            active
              ? [classes.image, classes.imageActive].join(" ")
              : classes.image
          }
        >
          <img
            className={classes.cow}
            src={window.innerWidth > 1220 ? cow : cow_mob}
            alt=""
          />
          <img className={classes.polygon} src={polygon} alt="" />
          <img className={classes.sphere} src={sphere} alt="" />
          <img className={classes.hemisphere} src={hemisphere} alt="" />
          <img className={classes.bagel_half} src={bagel_half} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Banner;
