import React, { useRef, useEffect } from "react";
import classes from "./QuickFacts.module.scss";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import bagel_half from "../../../assets/img/QuickStart/item_bagel_half.png";
import bagel from "../../../assets/img/QuickStart/item_bagel.png";
import hemisphere from "../../../assets/img/QuickStart/item_hemisphere.png";
import polygon from "../../../assets/img/QuickStart/item_polygon.png";
import sphere from "../../../assets/img/QuickStart/item_sphere.png";
import { useTranslation } from "react-i18next";
const QuickFacts = () => {
  const { t } = useTranslation();
  const ref = useRef(null);
  gsap.registerPlugin(ScrollTrigger);
  useEffect(() => {
    const element = ref.current;
    gsap.timeline().fromTo(
      element.querySelector(`#items`),
      {
        y: 300,
        opacity: 0,
      },
      {
        y: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: element.querySelector(`#items`),
          start: "top bottom",
          end: "top center",
          scrub: 5,
          id: "scrub",
        },
      }
    );
    if (document.documentElement.clientWidth > 1220) {
      gsap.timeline().fromTo(
        element.querySelector(`#plate_01`),
        {
          x: 600,
        },
        {
          x: 0,
          scrollTrigger: {
            trigger: element.querySelector(`#plate_01`),
            start: "top bottom",
            end: "top center",
            scrub: 5,
            id: "scrub",
          },
        }
      );
      gsap.timeline().fromTo(
        element.querySelector(`#plate_02`),
        {
          x: -600,
        },
        {
          x: 0,
          scrollTrigger: {
            trigger: element.querySelector(`#plate_02`),
            start: "top bottom",
            end: "top center",
            scrub: 5,
            id: "scrub",
          },
        }
      );
      gsap.timeline().fromTo(
        element.querySelector(`#plate_03`),
        {
          x: 600,
        },
        {
          x: 0,
          scrollTrigger: {
            trigger: element.querySelector(`#plate_03`),
            start: "top bottom",
            end: "top center",
            scrub: 5,
            id: "scrub",
          },
        }
      );
      gsap.timeline().fromTo(
        element.querySelector(`#plate_04`),
        {
          x: -600,
        },
        {
          x: 0,
          scrollTrigger: {
            trigger: element.querySelector(`#plate_04`),
            start: "top bottom",
            end: "top center",
            scrub: 5,
            id: "scrub",
          },
        }
      );
    }
  }, []);
  return (
    <div>
      <div className={classes.quickFacts} ref={ref}>
        <div className={[classes.header, "header-default"].join(" ")}>
          {t("quickstart_six__title")}
        </div>
        <div className={classes.content}>
          <div id="items" className={classes.items}>
            {/* 1st plate */}
            <img className={classes.item_01} src={polygon} alt="" />
            <img className={classes.item_02} src={bagel_half} alt="" />
            <img className={classes.item_03} src={sphere} alt="" />
            {/* 2nd plate */}
            <img className={classes.item_04} src={sphere} alt="" />
            <img className={classes.item_05} src={hemisphere} alt="" />
            {/* 3rd plate */}
            <img className={classes.item_06} src={hemisphere} alt="" />
            <img className={classes.item_07} src={bagel} alt="" />
            {/* 4rd plate */}
            <img className={classes.item_08} src={bagel_half} alt="" />
            <img className={classes.item_09} src={polygon} alt="" />
          </div>
          <div
            id="plate_01"
            className={[
              classes.plate,
              classes.plateRight,
              classes.plate_01,
            ].join(" ")}
          >
            <div className={classes.num}>1</div>
            <div className={classes.text}>
              <div className={classes.first}>
                {t("quickstart_six__item1__title")}
              </div>
              <div className={classes.second}>
                {t("quickstart_six__item1__subtitle")}
              </div>
            </div>
          </div>
          <div
            id="plate_02"
            className={[
              classes.plate,
              classes.plateLeft,
              classes.plate_01,
            ].join(" ")}
          >
            <div className={classes.text}>
              <div className={classes.first}>
                {t("quickstart_six__item2__title")}
              </div>
              <div className={classes.second}>
                {t("quickstart_six__item2__subtitle")}
              </div>
            </div>
            <div className={classes.num}>2</div>
          </div>
          <div
            id="plate_03"
            className={[
              classes.plate,
              classes.plateRight,
              classes.plate_01,
            ].join(" ")}
          >
            <div className={classes.num}>3</div>
            <div className={classes.text}>
              <div className={classes.first}>
                {t("quickstart_six__item3__title")}
              </div>
              <div className={classes.second}>
                {t("quickstart_six__item3__subtitle")}
              </div>
            </div>
          </div>
          <div
            id="plate_04"
            className={[
              classes.plate,
              classes.plateLeft,
              classes.plate_01,
            ].join(" ")}
          >
            <div className={classes.text}>
              <div className={classes.first}>
                {t("quickstart_six__item4__title")}
              </div>
              <div className={classes.second}>
                {t("quickstart_six__item4__subtitle")}
              </div>
            </div>
            <div className={classes.num}>4</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuickFacts;
