import React from "react";
import classes from "./Forex.module.scss";
import img from "../../../assets/img/education/1/1.png";
import LightBtn from "../../../UI/LightBtn/LightBtn";
import { Trans, useTranslation } from "react-i18next";
const Forex = () => {
  const { t } = useTranslation();
  return (
    <div className={classes.forex}>
      <div className={classes.forexBody}>
        <div className="container">
          <div className={classes.forexRow}>
            <div className={classes.forexRowLeft}>
              <div className={classes.forexRowLeftTitle}>
                <Trans>{t("education_first__title")}</Trans>
              </div>
              <div className={classes.forexRowLeftSubtitle}>
                {t("education_first__subtitle")}
              </div>
              <LightBtn />
            </div>
            <div className={classes.forexRowRight}>
              <img src={img} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Forex;
