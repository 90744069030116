import React from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import classes from "./AssistTrade.module.scss";
import img1 from "../../../assets/img/Main/assist-trade-1.png";
import img2 from "../../../assets/img/Main/assist-trade-2.png";
import img3 from "../../../assets/img/Main/assist-trade-3.png";
import img4 from "../../../assets/img/Main/assist-trade-4.png";
import icon1 from "../../../assets/img/Main/coin-1.png"
import icon2 from "../../../assets/img/Main/coin-2.png"
import icon3 from "../../../assets/img/Main/coin-3.png"
import icon4 from "../../../assets/img/Main/coin-4.png"
import { Trans, useTranslation } from "react-i18next";

gsap.registerPlugin(ScrollTrigger);
const AssistTrade = () => {
  const { t } = useTranslation();
  gsap.to('.assistTradeImg1', {
    y: 500,
    scale: .6,
    scrollTrigger: {
      trigger: ".assistTradeImg1",
      start: "top 100px",
      end: "bottom 0px",
      scrub: 1,
      id: "scrub"
    }
  });
  gsap.to('.assistTradeImg2', {
    y: 500,
    scale: .6,
    scrollTrigger: {
      trigger: ".assistTradeImg2",
      start: "top 100px",
      end: "bottom 0px",
      scrub: 1,
      id: "scrub"
    }
  });
  gsap.to('.assistTradeImg3', {
    y: 500,
    scale: .6,
    scrollTrigger: {
      trigger: ".assistTradeImg3",
      start: "top 100px",
      end: "bottom 0px",
      scrub: 1,
      id: "scrub"
    }
  });
  gsap.to('.assistTradeImg4', {
    y: 500,
    scale: .6,
    scrollTrigger: {
      trigger: ".assistTradeImg4",
      start: "top 100px",
      end: "bottom 0px",
      scrub: 1,
      id: "scrub"
    }
  });
  return (
    <div className={classes.assistTradeSection}>
      <div className={classes.assistTradeCryptoFirst}>
        <img src={icon1} alt="" className={[classes.assistTradeImg1, 'assistTradeImg1'].join(' ')} />
        <img src={icon3} alt="" className={[classes.assistTradeImg2, 'assistTradeImg2'].join(' ')} />
      </div>
      <div className={["container", classes.assistTradeContainer].join(" ")}>
        <h2 className={classes.assistTradeTitle}>{t("main_five__title")}</h2>
        <div className={classes.assistTradeRow}>
          <div className={classes.assistTradeCol3}>
            <div className={classes.assistTradeItem}>
              <img src={img1} alt="" className={classes.imgFluid} />
              <p>
                <Trans>{t("main_five__item1")}</Trans>
              </p>
            </div>
          </div>
          <div className={classes.assistTradeCol3}>
            <div className={classes.assistTradeItem}>
              <img src={img2} alt="" className={classes.imgFluid} />
              <p>
                <Trans>{t("main_five__item2")}</Trans>
              </p>
            </div>
          </div>
          <div className={classes.assistTradeCol3}>
            <div className={classes.assistTradeItem}>
              <img src={img3} alt="" className={classes.imgFluid} />
              <p>
                <Trans>{t("main_five__item3")}</Trans>
              </p>
            </div>
          </div>
          <div className={classes.assistTradeCol3}>
            <div className={classes.assistTradeItem}>
              <img src={img4} alt="" className={classes.imgFluid} />
              <p>
                <Trans>{t("main_five__item4")}</Trans>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.assistTradeCryptoSecond}>
        <img src={icon2} alt="" className={[classes.assistTradeImg3, 'assistTradeImg3'].join(' ')} />
        <img src={icon4} alt="" className={[classes.assistTradeImg4, 'assistTradeImg4'].join(' ')} />
      </div>
    </div>
  );
};

export default AssistTrade;
