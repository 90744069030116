import React from "react";
import img1 from "../../../assets/img/about/3/3.png";
import classes from "./Forex.module.scss";
import { useTranslation } from "react-i18next";
const Forex = (props) => {
  const { t } = useTranslation();

  return (
    <div
      className={
        props.forex ? [classes.forex, classes.hover].join(" ") : classes.forex
      }
    >
      <div className={classes.forexBody}>
        <div className="container">
          <div className={classes.forexRow}>
            <div className={classes.forexRowLeft}>
              <div className={classes.forexTitle}>
                {t("education_nine__title")}
              </div>
              <div className={classes.forexSubtitle}>
                {t("education_nine__subtitle1")}
              </div>
              <div className={classes.forexSubtitle}>
                {t("education_nine__subtitle2")}
              </div>
              <div className={classes.forexSubtitle}>
                {t("education_nine__subtitle3")}
              </div>
              <div className={classes.forexSubtitle}>
                {t("education_nine__subtitle4")}
              </div>
              <div className={classes.forexSubtitle}>
                {t("education_nine__subtitle5")}
              </div>
            </div>
            <div className={classes.forexRowRight}>
              <img src={img1} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Forex;
