import React from "react";
import classes from "./Spoiler.module.scss";
import { useTranslation } from "react-i18next";
const Spoiler = (props) => {
  const { t } = useTranslation();
  return (
    <div
      className={
        props.active
          ? [classes.spoiler, classes.active].join(" ")
          : classes.spoiler
      }
      onClick={props.clickEvent}
    >
      <div className={classes.spoilerTitle}>{t(props.title)}</div>
      <div className={classes.spoilerContent}>{t(props.text)}</div>
    </div>
  );
};

export default Spoiler;
