import React from "react";
import classes from "./Why.module.scss";
import { useTranslation } from "react-i18next";
const Why = (props) => {
  const { t } = useTranslation();
  return (
    <div
      className={
        props.why ? [classes.why, classes.hover].join(" ") : classes.why
      }
    >
      <div className={classes.whyBody}>
        <div className="container">
          <div className={classes.whyRow}>
            <div className={classes.whyRowLeft}>
              <div className={classes.whyRowLeftTitle}>
                {t("education_seven_left__title")}
              </div>
              <div className={classes.whyRowLeftSubtitle}>
                {t("education_seven_left_item1")}
              </div>
              <div className={classes.whyRowLeftSubtitle}>
                {t("education_seven_left_item2")}
              </div>
              <div className={classes.whyRowLeftSubtitle}>
                {t("education_seven_left_item3")}
              </div>
              <div className={classes.whyRowLeftSubtitle}>
                {t("education_seven_left_item4")}
              </div>
            </div>
            <div className={classes.whyRowRight}>
              <div className={classes.whyRowRightTitle}>
                {t("education_seven_right1__title")}
              </div>
              <div className={classes.whyRowRightSubtitle}>
                {t("education_seven_right1__subtitle")}
              </div>
              <div className={classes.whyRowRightTitle}>
                {t("education_seven_right2__title")}
              </div>
              <div className={classes.whyRowRightSubtitle}>
                {t("education_seven_right2__subtitle")}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Why;
