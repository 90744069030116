import React, { useState } from "react";
import Benefits from "../../components/EducationPage/Benefits/Benefits";
import Click from "../../components/EducationPage/Click/Click";
import Forex from "../../components/EducationPage/Forex/Forex";
import How from "../../components/EducationPage/How/How";
import Our from "../../components/EducationPage/Our/Our";
import Footer from "../../components/GlobalComponents/Footer/Footer";
import Header from "../../components/GlobalComponents/Header/Header";
import classes from "./EducationPage.module.scss";

const EducationPage = () => {
  const [how, setHow] = useState(false);
  const [benefits, setBenefits] = useState(false);
  const [our, setOur] = useState(false);
  const [click, setClick] = useState(false);
  return (
    <div
      id="education"
      className={classes.education}
      onScroll={() => {
        if (document.documentElement.clientWidth > 1220) {
          if (
            document.querySelector("#tap1").getBoundingClientRect().top < 450
          ) {
            setHow(true);
          } else {
            setHow(false);
          }

          if (
            document.querySelector("#tap2").getBoundingClientRect().top < 700
          ) {
            setBenefits(true);
          } else {
            setBenefits(false);
          }
          if (
            document.querySelector("#tap3").getBoundingClientRect().top < 800
          ) {
            setOur(true);
          } else {
            setOur(false);
          }
          if (
            document.querySelector("#tap4").getBoundingClientRect().top < 1000
          ) {
            setClick(true);
          } else {
            setClick(false);
          }
        }
      }}
    >
      <Header />
      <Forex />
      <div id="tap1">
        <How how={how} />
      </div>
      <div id="tap2">
        <Benefits benefits={benefits} />
      </div>
      <div id="tap3">
        <Our our={our} />
      </div>
      <div id="tap4">
        <Click click={click} />
      </div>
      <Footer />
    </div>
  );
};

export default EducationPage;
