import React from "react";
import * as THREE from 'three';
import gsap from "gsap";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";

const fragmentShaderMap = `
  uniform vec3 u_color_main;

  varying float vOpacity;

  float circle(vec2 st, float r) {
      vec2 dist = st - vec2(.5);
      return 1. - smoothstep(.99 * r, 1.01 * r, dot(dist, dist) * 4.);
  }

  void main() {
      float dot = circle(gl_PointCoord.xy, .7);
      if (dot < 0.5) discard;
      gl_FragColor = vec4(u_color_main, dot * vOpacity);
  }
`

const vertexShaderMap = `
  uniform sampler2D u_visibility;
  uniform float u_size;
  uniform float u_time_since_click;
  uniform vec3 u_clicked;

  varying float vOpacity;

  void main() {

      // mask with world map
      float visibility = 1. - step(.4, texture2D(u_visibility, uv).x);
      gl_PointSize = visibility * u_size;

      // add ripple
      float time = u_time_since_click;
      float dist = length(position - u_clicked);
      float damping = pow(1. - sin(min(time, 1.)), 5.);
      float wave = (1. + sin(3. * dist + 13. * time));
      float delta = -.025 * damping * wave;

      // make backside dots darker
      vec4 mvPosition = modelViewMatrix * vec4( position, 1.0 );
      vOpacity = (1. / length(mvPosition.xyz) - .7);
      vOpacity = clamp(vOpacity, 0., .5) + .01;

      gl_Position = projectionMatrix * modelViewMatrix * vec4(position * (1. + delta), 1.);
  }
`




class Surface extends React.Component {
  constructor(props) {
    super(props);
    const container = document.querySelector('.animation-wrapper');
    const globeCanvas = container.querySelector('#globe-3d');
    this.renderer = new THREE.WebGLRenderer({canvas: globeCanvas, alpha: true});
    this.scene = new THREE.Scene();
    this.camera = new THREE.OrthographicCamera(-1, 1, 1, -1, .1, 2);
    this.camera.position.z = 1.1;
    this.updateSize();

    this.rayCaster = new THREE.Raycaster();
    this.rayCaster.far = 1.15;
    this.mouse = new THREE.Vector2();
    this.coordinates2D = [0, 0];

    this.setupOverlayGraphic();
    this.createOrbitControls();

    this.clock = new THREE.Clock();
    this.clickTime = 0;

    this.group = new THREE.Group();
    this.group.scale.setScalar(0.9);
    this.scene.add(this.group);

    this.selectionVisible = false;
  }

  createOrbitControls() {
    const container = document.querySelector('.animation-wrapper');
    const globeCanvas = container.querySelector('#globe-3d');
    this.controls = new OrbitControls(this.camera, globeCanvas);
    this.controls.enablePan = true;
    this.controls.enableZoom = false;
    this.controls.enableDamping = true;
    this.controls.minPolarAngle = .35 * Math.PI;
    this.controls.maxPolarAngle = .65 * Math.PI;
    this.controls.autoRotate = true;
  }

  createGlobe() {
    const globeGeometry = new THREE.IcosahedronGeometry(1, 14);
    this.mapMaterial = new THREE.ShaderMaterial({
      vertexShader: vertexShaderMap,
      fragmentShader: fragmentShaderMap,
      uniforms: {
        u_visibility: {type: 't', value: this.earthTexture},
        u_size: {type: 'f', value: 0},
        u_color_main: {type: 'v3', value: new THREE.Color(0x636399)},
        u_clicked: {type: 'v3', value: new THREE.Vector3(.0, .0, 1.)},
        u_time_since_click: {value: 3.},
      },
      alphaTest: false,
      transparent: true
    });

    const globe = new THREE.Points(globeGeometry, this.mapMaterial);
    this.group.add(globe);

    this.blackGlobe = new THREE.Mesh(globeGeometry, new THREE.MeshBasicMaterial({
      color: 0xBECEF7,
      transparent: false,
      opacity: 1
    }));
    this.blackGlobe.scale.setScalar(0.99);
    this.group.add(this.blackGlobe);
  }

  addAnchor() {
    const geometry = new THREE.SphereGeometry(.02, 16, 16);
    const material = new THREE.MeshBasicMaterial({
      color: 0x636399,
      transparent: false,
      opacity: 1
    });
    this.anchor = new THREE.Mesh(geometry, material);
    this.group.add(this.anchor);
  }

  setupOverlayGraphic() {
    const container = document.querySelector('.animation-wrapper');
    const globeOverlayCanvas = container.querySelector('#globe-2d-overlay');
    this.overlayCtx = globeOverlayCanvas.getContext('2d');
    this.overlayCtx.strokeStyle = '#636399';
    this.overlayCtx.lineWidth = 5;
    this.overlayCtx.lineCap = 'round';
  }

  updateOverlayGraphic() {
    const container = document.querySelector('.animation-wrapper');
    const popup = container.querySelector('.globe-popup');
    if (this.anchor) {
      let activePointPosition = this.anchor.position.clone();
      activePointPosition.applyMatrix4(this.group.matrixWorld);
      const activePointPositionProjected = activePointPosition.clone();
      activePointPositionProjected.project(this.camera);
      this.coordinates2D[0] = (activePointPositionProjected.x + 1) * container.offsetWidth * .5;
      this.coordinates2D[1] = (-activePointPositionProjected.y + 1) * container.offsetHeight * .5;

      const matrixWorldInverse = this.controls.object.matrixWorldInverse;
      activePointPosition.applyMatrix4(matrixWorldInverse);

      if (activePointPosition.z > -1) {
        if (this.selectionVisible === false) {
          this.selectionVisible = true;
          this.showPopupAnimation(false);
        }

        let popupX = this.coordinates2D[0];
        let popupY = this.coordinates2D[1];
        popupX -= (activePointPositionProjected.x * container.offsetWidth * .3);
        const upDown = (activePointPositionProjected.y > .6);
        popupY += (upDown ? 20 : -20);
        gsap.set(popup, {
          x: popupX,
          y: popupY,
          xPercent: -50,
          yPercent: upDown ? 0 : -100
        });

        popupY += (upDown ? -10 : 10);
        const curveStartX = this.coordinates2D[0];
        const curveStartY = this.coordinates2D[1];
        let curveMidX = popupX + activePointPositionProjected.x * 100;
        const curveMidY = popupY + (upDown ? -.5 : .1) * this.coordinates2D[1];

        this.drawPopupConnector(curveStartX, curveStartY, curveMidX, curveMidY, popupX, popupY);

      } else {
        if (this.selectionVisible) {
          this.hidePopupAnimation();
        }
        this.selectionVisible = false;
      }
    }
  }

  addCanvasEvents() {
    const container = document.querySelector('.animation-wrapper');
    const popup = container.querySelector('.globe-popup');
    container.addEventListener('mousemove', (e) => {
      updateMousePosition(e.clientX, e.clientY, this);
    });

    function updateMousePosition(eX, eY, surface) {
      const container = document.querySelector('.animation-wrapper');
      const x = eX - container.offsetLeft;
      const y = eY - container.offsetTop;
      surface.mouse.x = x / container.offsetWidth * 2 - 1;
      surface.mouse.y = -(y / container.offsetHeight) * 2 + 1;
    }

    container.addEventListener('click', (e) => {
      updateMousePosition(
        e.targetTouches ? e.targetTouches[0].pageX : e.clientX,
        e.targetTouches ? e.targetTouches[0].pageY : e.clientY,
        this);
      this.checkIntersects();
      if (this.landIsHovered) {
        const intersects = this.rayCaster.intersectObject(this.blackGlobe);
        if (intersects.length) {
          this.anchor.position.set(intersects[0].face.normal.x, intersects[0].face.normal.y, intersects[0].face.normal.z);
          this.mapMaterial.uniforms.u_clicked.value = this.anchor.position;
          popup.innerHTML = this.getLatLong();
          this.showPopupAnimation(true);
          gsap.delayedCall(.15, () => {
            this.clickTime = this.clock.getElapsedTime();
          })
        }
      }
    });
  }

  drawPopupConnector(startX, startY, midX, midY, endX, endY) {
    const container = document.querySelector('.animation-wrapper');
    this.overlayCtx.clearRect(0, 0, container.offsetWidth, container.offsetHeight);
    this.overlayCtx.beginPath();
    this.overlayCtx.shadowOffsetX = startX > endX ? -4 : 4;
    this.overlayCtx.moveTo(startX, startY);
    this.overlayCtx.quadraticCurveTo(midX, midY, endX, endY);
    this.overlayCtx.stroke();
  }

  showPopupAnimation(lifted) {
    const container = document.querySelector('.animation-wrapper');
    const globeOverlayCanvas = container.querySelector('#globe-2d-overlay');
    const popup = container.querySelector('.globe-popup');
    let positionLifted = this.anchor.position.clone();
    if (lifted) {
      positionLifted.multiplyScalar(1.1);
    }
    gsap.timeline({ })
      .fromTo(this.anchor.position, {
        x: positionLifted.x,
        y: positionLifted.y,
        z: positionLifted.z,
      }, {
        duration: .35,
        x: this.anchor.position.x,
        y: this.anchor.position.y,
        z: this.anchor.position.z,
        ease: 'back(4).out'
      }, 0)
      .to(this.anchor.material, {
        duration: .2,
        opacity: 1,
      }, 0)
      .fromTo(globeOverlayCanvas, {
        opacity: 0
      }, {
        duration: 0.3,
        opacity: 1
      }, .15)
      .fromTo(popup, {
        opacity: 0,
        scale: .9,
        transformOrigin: 'center bottom'
      }, {
        duration: 0.1,
        opacity: 1,
        scale: 1,
      }, .15 + .1)
  }

  hidePopupAnimation() {
    const container = document.querySelector('.animation-wrapper');
    const popup = container.querySelector('.globe-popup');
    const globeOverlayCanvas = container.querySelector('#globe-2d-overlay');
    gsap.timeline({ })
      .to(this.anchor.material, {
        duration: .1,
        opacity: .2,
      }, 0)
      .to(globeOverlayCanvas, {
        duration: 0.15,
        opacity: 0
      }, 0)
      .to(popup, {
        duration: 0.15,
        opacity: 0,
        scale: 0.9,
        transformOrigin: 'center bottom'
      }, 0);
  }

  getImageData() {
    const image = this.earthTexture.image;
    const canvas = document.createElement('canvas');
    canvas.width = image.width;
    canvas.height = image.height;
    const context = canvas.getContext( '2d' );
    context.drawImage(image, 0, 0);
    return context.getImageData(0, 0, image.width, image.height);
  }

  getLatLong() {
    const pos = this.anchor.position;
    const lat = 90 - (Math.acos(pos.y)) * 180 / Math.PI;
    const lng = ((270 + (Math.atan2(pos.x, pos.z)) * 180 / Math.PI) % 360) - 180;
    return lat.toFixed(6) + ',&nbsp;' + lng.toFixed(6);
  }

  checkIntersects() {
    let isLand = (imageData, x, y) => {
      x = Math.floor(x * imageData.width);
      y = Math.floor((1 - y) * imageData.height);
      const position = (x + imageData.width * y) * 4;
      const data = imageData.data;
      return data[position] < 100;
    };
    this.rayCaster.setFromCamera(this.mouse, this.camera);
    const intersects = this.rayCaster.intersectObject(this.blackGlobe);
    if (intersects.length) {
      this.landIsHovered = isLand(this.earthTextureData, intersects[0].uv.x, intersects[0].uv.y);
      document.body.style.cursor = this.landIsHovered ? 'pointer' : 'auto';
    } else {
      document.body.style.cursor = 'auto';
    }
  }

  renderGlobe() {
    this.mapMaterial.uniforms.u_time_since_click.value = this.clock.getElapsedTime() - this.clickTime;
    this.updateOverlayGraphic();
    this.controls.update();
    this.checkIntersects();
    this.renderer.render(this.scene, this.camera);
  }

  loop() {
    this.renderGlobe();
    requestAnimationFrame(this.loop.bind(this));
  }

  updateSize() {
    const container = document.querySelector('.animation-wrapper');
    const globeOverlayCanvas = container.querySelector('#globe-2d-overlay');
    const minSide = .55 * Math.min(window.innerWidth, window.innerHeight);
    container.style.width = minSide + 'px';
    container.style.height = minSide + 'px';
    this.renderer.setSize(minSide, minSide);
    this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    this.camera.updateProjectionMatrix();
    globeOverlayCanvas.width = minSide;
    globeOverlayCanvas.height = minSide;
  }

  updateDotSize() {
    const container = document.querySelector('.animation-wrapper');
    this.mapMaterial.uniforms.u_size.value = .03 * container.offsetWidth;
  }
  render() {
    return false
  }
}

export default Surface;
