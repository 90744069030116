import React from "react";
import classes from "./Table.module.scss";
import LightBtn from "../../../UI/LightBtn/LightBtn";
import { useTranslation } from "react-i18next";
const Table = () => {
  const { t } = useTranslation();
  return (
    <div className={["container", classes.tableContainer].join(" ")}>
      <div className={classes.tableWrap}>
        <div className={classes.gridWrap}>
          <div className={classes.grid1}></div>
          <div className={[classes.grid2, classes.tableColTop].join(" ")}></div>
          <div className={[classes.grid3, classes.tableColTop].join(" ")}></div>
          <div className={[classes.grid4, classes.tableColTop].join(" ")}></div>
          <div
            className={[
              classes.grid5,
              classes.bgGrey,
              classes.tableCol,
              classes.tableHeader,
              classes.tableBorderNone,
            ].join(" ")}
          >
            {t("main_eight_top1")}
          </div>
          <div
            className={[
              classes.grid6,
              classes.bgGrey,
              classes.tableCol,
              classes.tableHeader,
            ].join(" ")}
          >
            {t("main_eight_top2")}
          </div>
          <div
            className={[
              classes.grid7,
              classes.bgGrey,
              classes.tableCol,
              classes.tableHeader,
            ].join(" ")}
          >
            {t("main_eight_top3")}
          </div>
          <div className={[classes.grid8, classes.tableCol].join(" ")}></div>
          <div
            className={[
              classes.grid9,
              classes.tableCol,
              classes.tableBorderNone,
            ].join(" ")}
          >
            {t("main_eight_left1")}
          </div>
          <div className={[classes.grid10, classes.tableCol].join(" ")}>
            2,3 %
          </div>
          <div className={[classes.grid11, classes.tableCol].join(" ")}>
            {t("main_eight_right1")}
          </div>
          <div className={[classes.grid12, classes.tableCol].join(" ")}>
            <LightBtn text={t("btn_earning")} setClass={classes.tableLink} />
          </div>
          <div
            className={[
              classes.grid13,
              classes.tableCol,
              classes.tableBorderNone,
              classes.bgGrey,
            ].join(" ")}
          >
            {t("main_eight_left2")}
          </div>
          <div
            className={[classes.grid15, classes.tableCol, classes.bgGrey].join(
              " "
            )}
          >
            2,4 %
          </div>
          <div
            className={[classes.grid16, classes.tableCol, classes.bgGrey].join(
              " "
            )}
          >
            {t("main_eight_right2")}
          </div>
          <div
            className={[classes.grid17, classes.tableCol, classes.bgGrey].join(
              " "
            )}
          >
            <LightBtn text={t("btn_earning")} setClass={classes.tableLink} />
          </div>
          <div
            className={[
              classes.grid18,
              classes.tableCol,
              classes.tableBorderNone,
            ].join(" ")}
          >
            {t("main_eight_left3")}
          </div>
          <div className={[classes.grid19, classes.tableCol].join(" ")}>
            0,61 %
          </div>
          <div className={[classes.grid20, classes.tableCol].join(" ")}>
            {t("main_eight_right3")}
          </div>
          <div className={[classes.grid21, classes.tableCol].join(" ")}>
            <LightBtn text={t("btn_earning")} setClass={classes.tableLink} />
          </div>
          <div
            className={[
              classes.grid22,
              classes.tableCol,
              classes.tableBorderNone,
              classes.bgGrey,
            ].join(" ")}
          >
            {t("main_eight_left4")}
          </div>
          <div
            className={[classes.grid23, classes.tableCol, classes.bgGrey].join(
              " "
            )}
          >
            2,61 %
          </div>
          <div
            className={[classes.grid24, classes.tableCol, classes.bgGrey].join(
              " "
            )}
          >
            {t("main_eight_right4")}
          </div>
          <div
            className={[classes.grid25, classes.tableCol, classes.bgGrey].join(
              " "
            )}
          >
            <LightBtn text={t("btn_earning")} setClass={classes.tableLink} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Table;
