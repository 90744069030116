import { BrowserRouter } from "react-router-dom";
import Router from "./components/GlobalComponents/Router/AppRouter";
import './App.scss'
import { useState, useEffect } from "react";
import { BallTriangle } from "react-loader-spinner";

function App() {
  const [ loading, setLoading ] = useState(true);
  useEffect(() => {
    const onPageLoad = () => {
      setTimeout(() => setLoading(false), 1000)
    };
    if (document.readyState === "complete") {
      onPageLoad();
    } else {
      window.addEventListener("load", onPageLoad);
    }
  }, []);
  return (
    <div>
      <BrowserRouter>
      {loading &&
        <div className="preloader">
          <BallTriangle
            height={100}
            width={100}
            radius={5}
            color="#36336C"
            ariaLabel="ball-triangle-loading"
            wrapperStyle={{marginLeft: 'calc(50vw - 50px)', marginTop: 'calc(50vh - 100px)'}}
            visible={true}
          />
        </div>
      }
        <Router />
      </BrowserRouter>
    </div>
  );
}

export default App;
