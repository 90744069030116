import React, { useState, useEffect } from "react";
import classes from "./Header.module.scss";
import logo from "../../../assets/img/header/logo.svg";
import arrow from "../../../assets/img/header/arrow.svg";
import { headerRoutes } from "../../../router/index";
import { Link, useLocation } from "react-router-dom";
import LightBtn from "../../../UI/LightBtn/LightBtn";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import Cookies from "js-cookie";

const Header = () => {
  const { t } = useTranslation();
  const [localA, setLocalA] = useState(false);
  const [burgerA, setBurgerA] = useState(false);

  const [defaultOption, setDefaultOption] = useState(Cookies.get("i18next"));

  useEffect(() => {
    setDefaultOption(Cookies.get("i18next"));
  }, []);

  const languages = [
    { value: "ru", label: "Ru" },
    { value: "en", label: "En" },
    { value: "es", label: "Es" },
    { value: "it", label: "It" },
    { value: "de", label: "De" },
    { value: "pl", label: "Pl" },
  ];

  let location = useLocation();
  const [numNav, setNumNav] = useState(0);

  useEffect(() => {
    if (location.pathname === "/quickstart") {
      document.querySelector("body").classList.remove("lock");
      setNumNav(1);
    } else if (location.pathname === "/education") {
      document.querySelector("body").classList.add("lock");
      setNumNav(2);
    } else if (location.pathname === "/about") {
      document.querySelector("body").classList.add("lock");
      setNumNav(3);
    } else if (location.pathname === "/faq") {
      document.querySelector("body").classList.add("lock");
      setNumNav(4);
    } else if (location.pathname === "/") {
      setNumNav(0);
      document.querySelector("body").classList.remove("lock");
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className={classes.header}>
      <div className={classes.headerBody}>
        <div className="container">
          <div className={classes.headerRow}>
            <div
              className={
                burgerA
                  ? [classes.headerRowLogo, classes.active].join(" ")
                  : classes.headerRowLogo
              }
            >
              <Link
                to="/"
                onClick={() => {
                  setNumNav(0);
                }}
              >
                <img src={logo} alt="" />
              </Link>
            </div>

            <div className={classes.headerRowRight}>
              <div
                className={
                  burgerA
                    ? [classes.headerRowCenterWrapper, classes.active].join(" ")
                    : classes.headerRowCenterWrapper
                }
              >
                <div className={classes.headerRowCenter}>
                  {// eslint-disable-next-line
                  headerRoutes.map((i, index) => {
                    if (i.id === 1) {
                      return (
                        <Link
                          key={index}
                          to={i.path}
                          onClick={() => {
                            setNumNav(1);
                          }}
                          className={
                            numNav === 1
                              ? [
                                  classes.headerRowCenterItem,
                                  classes.active,
                                ].join(" ")
                              : classes.headerRowCenterItem
                          }
                        >
                          {t(i.text)}
                        </Link>
                      );
                    } else if (i.id === 2) {
                      return (
                        <Link
                          key={index}
                          onClick={() => {
                            document
                              .querySelector("body")
                              .classList.add("lock");
                            setNumNav(2);
                          }}
                          to={i.path}
                          className={
                            numNav === 2
                              ? [
                                  classes.headerRowCenterItem,
                                  classes.active,
                                ].join(" ")
                              : classes.headerRowCenterItem
                          }
                        >
                          {t(i.text)}
                        </Link>
                      );
                    } else if (i.id === 3) {
                      return (
                        <Link
                          onClick={() => {
                            document
                              .querySelector("body")
                              .classList.add("lock");
                            setNumNav(3);
                          }}
                          key={index}
                          to={i.path}
                          className={
                            numNav === 3
                              ? [
                                  classes.headerRowCenterItem,
                                  classes.active,
                                ].join(" ")
                              : classes.headerRowCenterItem
                          }
                        >
                          {t(i.text)}
                        </Link>
                      );
                    } else if (i.id === 4) {
                      return (
                        <Link
                          onClick={() => {
                            document
                              .querySelector("body")
                              .classList.add("lock");
                            setNumNav(4);
                          }}
                          key={index}
                          to={i.path}
                          className={
                            numNav === 4
                              ? [
                                  classes.headerRowCenterItem,
                                  classes.active,
                                ].join(" ")
                              : classes.headerRowCenterItem
                          }
                        >
                          {t(i.text)}
                        </Link>
                      );
                    }
                  })}

                  <div
                    className={
                      burgerA
                        ? [
                            classes.headerRowRightLogBtn,
                            classes.active,
                            classes.mob,
                          ].join(" ")
                        : [classes.headerRowRightLogBtn, classes.mob].join(" ")
                    }
                  >
                    <LightBtn text={t("header__log")} black={true} />
                  </div>
                  <div
                    className={
                      burgerA
                        ? [
                            classes.headerRowRightSignBtn,
                            classes.active,
                            classes.mob,
                          ].join(" ")
                        : [classes.headerRowRightSignBtn, classes.mob].join(" ")
                    }
                  >
                    <LightBtn text={t("header__sign")} />
                  </div>
                  <div
                    className={[
                      classes.headerRowRightLocalization,
                      classes.mob,
                    ].join(" ")}
                    onClick={() => {
                      setLocalA(!localA);
                    }}
                  >
                    <div
                      className={
                        localA
                          ? burgerA
                            ? [
                                classes.headerRowRightLocalizationTitle,
                                classes.active,
                                classes.white,
                              ].join(" ")
                            : [
                                classes.headerRowRightLocalizationTitle,
                                classes.active,
                              ].join(" ")
                          : burgerA
                          ? [
                              classes.headerRowRightLocalizationTitle,
                              classes.white,
                            ].join(" ")
                          : classes.headerRowRightLocalizationTitle
                      }
                    >
                      {defaultOption}
                      <img src={arrow} alt="" />
                    </div>
                    <div
                      className={
                        localA
                          ? [
                              classes.headerRowRightLocalizationItems,
                              classes.active,
                            ].join(" ")
                          : classes.headerRowRightLocalizationItems
                      }
                    >
                      {languages.map((i, index) => {
                        return (
                          <p
                            key={index}
                            onClick={() => {
                              i18n.changeLanguage(i.value);
                              setDefaultOption(i.value);
                            }}
                          >
                            {i.value}
                          </p>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={classes.headerRowRightLocalization}
                onClick={() => {
                  setLocalA(!localA);
                }}
              >
                <div
                  className={
                    localA
                      ? burgerA
                        ? [
                            classes.headerRowRightLocalizationTitle,
                            classes.active,
                            classes.white,
                          ].join(" ")
                        : [
                            classes.headerRowRightLocalizationTitle,
                            classes.active,
                          ].join(" ")
                      : burgerA
                      ? [
                          classes.headerRowRightLocalizationTitle,
                          classes.white,
                        ].join(" ")
                      : classes.headerRowRightLocalizationTitle
                  }
                >
                  {defaultOption}
                  <img src={arrow} alt="" />
                </div>
                <div
                  className={
                    localA
                      ? [
                          classes.headerRowRightLocalizationItems,
                          classes.active,
                        ].join(" ")
                      : classes.headerRowRightLocalizationItems
                  }
                >
                  {languages.map((i, index) => {
                    return (
                      <p
                        key={index}
                        onClick={() => {
                          i18n.changeLanguage(i.value);
                          setDefaultOption(i.value);
                        }}
                      >
                        {i.value}
                      </p>
                    );
                  })}
                </div>
              </div>
              <div
                className={
                  burgerA
                    ? [classes.headerRowRightLogBtn, classes.active].join(" ")
                    : classes.headerRowRightLogBtn
                }
              >
                <LightBtn text={t("header__log")} black={true} />
              </div>
              <div
                className={
                  burgerA
                    ? [classes.headerRowRightSignBtn, classes.active].join(" ")
                    : classes.headerRowRightSignBtn
                }
              >
                {/* КНОПКА ДЕСКТОП */}
                <LightBtn href="https://account.evocrypto.tech/signup" text={t("header__sign")} />
              </div>
              <div
                className={
                  burgerA
                    ? [classes.headerRowRightBurger, classes.active].join(" ")
                    : classes.headerRowRightBurger
                }
                onClick={() => {
                  setBurgerA(!burgerA);
                }}
              >
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
