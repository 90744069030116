import React from "react";
import classes from "./Footer.module.scss";
import { Trans, useTranslation } from "react-i18next";
const Footer = () => {
  const { t } = useTranslation();
  return (
    <div className="container">
      <div className={classes.footer}>
        <div className={classes.col_01}>
          <div className={classes.row}>
            <div className={classes.header}>{t("footer_left__title1")}</div>
            <div className={classes.text}>
              <Trans>{t("footer_left__subtitle1")}</Trans>
            </div>
          </div>
          <div className={classes.row}>
            <div className={classes.header}> {t("footer_left__title2")}</div>
            <div className={classes.text}>
              <Trans>{t("footer_left__subtitle2")}</Trans>
            </div>
          </div>
          <div className={classes.row}>
            <div className={classes.text}>Graincorner LTD, 10 Manoel str., Castries, st. Lucia</div>
            <div className={classes.text}>{t("footer_left__item1")}</div>
            <div className={classes.text}>{t("footer_left__item2")}</div>
            <div className={classes.text}>{t("footer_left__item3")}</div>
          </div>
        </div>
        <div className={classes.col_02}>
          <a href={'/docs/en-terms.pdf'}> {t("footer_center__item1")}</a>
          <a href={'/docs/en-privacy-policy.pdf'} className={classes.row}> {t("footer_center__item2")}</a>
          <a href={'/docs/en-aml-policy.pdf'} className={classes.row}> {t("footer_center__item3")}</a>
          {/* <div className={classes.row}> {t("footer_center__item4")}</div> */}
        </div>
        <div className={classes.col_03}>
          <div>{t("footer_right__title")}</div>
          <div className={classes.row}>
            {t("footer_right__subtitle")}
            <br />
            <a className={classes.telA} href="tel:+14732300334">
              +1 473 230 03 34
            </a>
            <br />
            info@evocrypto.io
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
