import React, { useEffect, useRef } from "react";
import classes from "./ForexTrading.module.scss";
import eth from "../../../assets/img/QuickStart/item_eth.png";
import euro from "../../../assets/img/QuickStart/item_euro.png";
import shield from "../../../assets/img/QuickStart/item_shield.png";
import sphere from "../../../assets/img/QuickStart/item_sphere.png";
import bagel from "../../../assets/img/QuickStart/item_bagel.png";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import LightBtn from "../../../UI/LightBtn/LightBtn";
import { Trans, useTranslation } from "react-i18next";
const ForexTrading = () => {
  const { t } = useTranslation();
  const ref = useRef(null);
  gsap.registerPlugin(ScrollTrigger);
  useEffect(() => {
    const element = ref.current;
    if (document.documentElement.clientWidth > 1220) {
      gsap.timeline().to(element.querySelector(`#images`), {
        y: 400,
        scrollTrigger: {
          trigger: element.querySelector(`#images`),
          start: "top center",
          end: "top top",
          scrub: 5,
          id: "scrub",
        },
      });
      gsap.timeline().to(element.querySelector(`#euroLeft`), {
        rotationZ: 40,
        scrollTrigger: {
          trigger: element.querySelector(`#images`),
          start: "top center",
          end: "top top",
          scrub: 5,
          id: "scrub",
        },
      });
      gsap.timeline().to(element.querySelector(`#euroRight`), {
        rotationZ: -50,
        scrollTrigger: {
          trigger: element.querySelector(`#images`),
          start: "top center",
          end: "top top",
          scrub: 5,
          id: "scrub",
        },
      });
      gsap.timeline().to(element.querySelector(`#shield`), {
        top: 200,
        scrollTrigger: {
          trigger: element.querySelector(`#images`),
          start: "top center",
          end: "top top",
          scrub: 5,
          id: "scrub",
        },
      });
      gsap.timeline().to(element.querySelector(`#sphere`), {
        top: 140,
        scrollTrigger: {
          trigger: element.querySelector(`#images`),
          start: "top center",
          end: "top top",
          scrub: 5,
          id: "scrub",
        },
      });
      gsap.timeline().to(element.querySelector(`#bagel`), {
        top: -100,
        scrollTrigger: {
          trigger: element.querySelector(`#images`),
          start: "top center",
          end: "top top",
          scrub: 5,
          id: "bagel",
        },
      });
    }
  }, []);
  return (
    <div className="container">
      <div className={classes.forexTrading} ref={ref}>
        <div className={classes.content}>
          <div className={[classes.header, "header-default"].join(" ")}>
            {t("quickstart_second__title")}
          </div>
          <div className={classes.text}>
            <div>
              <Trans>{t("quickstart_second__item1")}</Trans>
            </div>
            <div className={classes.par}>{t("quickstart_second__item2")}</div>
            <div className={classes.par}>{t("quickstart_second__item3")}</div>
          </div>
          <div className={classes.btn}>
            <LightBtn />
          </div>
        </div>
        <div id="images" className={classes.images}>
          <img id="eth" className={classes.eth} src={eth} alt="" />
          <img id="euroLeft" className={classes.euroLeft} src={euro} alt="" />
          <img id="euroRight" className={classes.euroRight} src={euro} alt="" />
          <img id="shield" className={classes.shield} src={shield} alt="" />
          <img id="sphere" className={classes.sphere} src={sphere} alt="" />
          <img id="bagel" className={classes.bagel} src={bagel} alt="" />
        </div>
      </div>
    </div>
  );
};

export default ForexTrading;
