import React from "react";
import Header from "../../components/GlobalComponents/Header/Header";
import Banner from "../../components/MainPage/Banner/Banner";
import AdvantagesItems from "../../components/MainPage/AdvantagesItems/AdvantagesItems";
import BestTerms from "../../components/MainPage/BestTerms/BestTerms";
import TradingPlatform from "../../components/MainPage/TradingPlatform/TradingPlatform";
import AssistTrade from "../../components/MainPage/AssistTrade/AssistTrade";
import Have from "../../components/FaqPage/Have/Have";
import Considerable from "../../components/MainPage/Considerable/Considerable";
import Table from "../../components/MainPage/Table/Table";
import AllMarkets from "../../components/MainPage/AllMarkets/AllMarkets";
import Footer from "../../components/GlobalComponents/Footer/Footer";

const MainPage = () => {
    return (
        <div>
          <Header />
          <Banner />
          <AdvantagesItems />
          <BestTerms />
          <TradingPlatform />
          <AssistTrade />
          <Have />
          <Considerable />
          <Table />
          <AllMarkets />
          <Footer />
        </div>
    );
};

export default MainPage;
