import React from "react";
import classes from "./Considerable.module.scss";
import { useTranslation } from "react-i18next";

const Considerable = () => {
  const { t } = useTranslation();
  return (
    <div className={classes.considerableContainer}>
      <div className="container">
        <h2 className={classes.considerableTitle}>{t("main_seven__title")}</h2>
        <div className={classes.considerableWrap}>
          <div className={classes.considerableRow}>
            <div className={classes.considerableCol3}>
              <div className={classes.considerableItem}>
                <div className={classes.considerableItemBehind}>
                  <h5>{t('main_seven1__bot')}</h5>
                  <p>{t("main_seven1__top")}</p>
                </div>
                <div className={classes.considerableItemFront}>
                  <p className={classes.cb1}> {t("main_seven1__bot")}</p>
                </div>
              </div>
            </div>
            <div className={classes.considerableCol3}>
              <div className={classes.considerableItem}>
                <div className={classes.considerableItemBehind}>
                  <h5>{t('main_seven2__bot')}</h5>
                  <p>{t("main_seven2__top")}</p>
                </div>
                <div className={classes.considerableItemFront}>
                  <p> {t("main_seven2__bot")}</p>
                </div>
              </div>
            </div>
            <div className={classes.considerableCol3}>
              <div className={classes.considerableItem}>
                <div className={classes.considerableItemBehind}>
                  <h5>{t('main_seven3__bot')}</h5>
                  <p>{t("main_seven3__top")}</p>
                </div>
                <div className={classes.considerableItemFront}>
                  <p> {t("main_seven3__bot")}</p>
                </div>
              </div>
            </div>
            <div className={classes.considerableCol3}>
              <div className={classes.considerableItem}>
                <div className={classes.considerableItemBehind}>
                  <h5>{t('main_seven4__bot')}</h5>
                  <p>{t("main_seven4__top")}</p>
                </div>
                <div className={classes.considerableItemFront}>
                  <p> {t("main_seven4__bot")}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Considerable;
