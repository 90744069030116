import React, { useState } from "react";
import LightBtn from "../../../UI/LightBtn/LightBtn";
import classes from "./How.module.scss";
import img1 from "../../../assets/img/education/2/1.png";
import img2 from "../../../assets/img/education/2/2.png";
import img3 from "../../../assets/img/education/2/3.png";
import img4 from "../../../assets/img/education/2/4.png";
import img5 from "../../../assets/img/education/2/5.png";
import img6 from "../../../assets/img/education/2/6.png";
import img7 from "../../../assets/img/education/2/7.png";
import img8 from "../../../assets/img/education/2/8.png";
import { useTranslation } from "react-i18next";

const How = (props) => {
  const { t } = useTranslation();

  const [hoverAnim, setHoverAnim] = useState(false);

  return (
    <div
      className={
        props.how ? [classes.how, classes.scroll].join(" ") : classes.how
      }
    >
      <div className={classes.howBody}>
        <div className="container">
          <div className={classes.howWrapper}>
            <div className={classes.howTitle}>
              {t("education_second__title")}
            </div>
            <div className={classes.howSubtitle}>
              <span> {t("education_second__subtitle")}</span>
            </div>
            <div
              className={
                hoverAnim
                  ? [classes.howTextWrapper, classes.hover].join(" ")
                  : classes.howTextWrapper
              }
            >
              <div className={classes.howText}>
                <span>{t("education_second__item1")}</span>
              </div>
              <div className={classes.howText}>
                <span>{t("education_second__item2")}</span>
              </div>
              <div className={classes.howText}>
                <span>{t("education_second__item3")}</span>
              </div>
              <div className={classes.howText}>
                <span>{t("education_second__item4")}</span>
              </div>
              <div className={classes.howText}>
                <span>{t("education_second__item5")}</span>
              </div>
              <div className={classes.howText}>
                <span>{t("education_second__item6")}</span>
              </div>
              <div className={classes.howText}>
                <span>{t("education_second__item7")}</span>
              </div>
              <div className={classes.howImg1}>
                <img src={img1} alt="" />
              </div>
              <div className={classes.howImg2}>
                <img src={img2} alt="" />
              </div>
              <div className={classes.howImg3}>
                <img src={img3} alt="" />
              </div>
              <div className={classes.howImg4}>
                <img src={img4} alt="" />
              </div>
              <div className={classes.howImg5}>
                <img src={img5} alt="" />
              </div>
              <div className={classes.howImg6}>
                <img src={img6} alt="" />
              </div>
              <div className={classes.howImg7}>
                <img src={img7} alt="" />
              </div>
              <div className={classes.howImg8}>
                <img src={img8} alt="" />
              </div>
            </div>

            <div className={classes.howBtn}>
              <LightBtn
                onMouseEnter={() => {
                  setHoverAnim(true);
                }}
                onMouseLeave={() => {
                  setHoverAnim(false);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default How;
