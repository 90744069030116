import React, { useEffect, useRef } from "react";
import classes from "./Invincible.module.scss";
import planet from "../../../assets/img/QuickStart/invincible_planet.png";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import LightBtn from "../../../UI/LightBtn/LightBtn";
import { Trans, useTranslation } from "react-i18next";
const Invincible = () => {
  const { t } = useTranslation();
  const ref = useRef(null);
  gsap.registerPlugin(ScrollTrigger);
  useEffect(() => {
    const element = ref.current;
    if (document.documentElement.clientWidth > 1220) {
      gsap.timeline().fromTo(
        element.querySelector(`#planet`),
        {
          scale: 0.6,
        },
        {
          scale: 1,
          scrollTrigger: {
            trigger: element.querySelector(`#planet`),
            start: "top bottom",
            end: "top center",
            scrub: 3,
            id: "scrub",
          },
        }
      );
      gsap.timeline().fromTo(
        element.querySelector(`#plate_01`),
        {
          x: 70,
        },
        {
          x: 0,
          scrollTrigger: {
            trigger: element.querySelector(`#planet`),
            start: "top bottom",
            end: "top center",
            scrub: 3,
            id: "scrub",
          },
        }
      );
      gsap.timeline().fromTo(
        element.querySelector(`#plate_02`),
        {
          x: -90,
        },
        {
          x: 0,
          scrollTrigger: {
            trigger: element.querySelector(`#planet`),
            start: "top bottom",
            end: "top center",
            scrub: 3,
            id: "scrub",
          },
        }
      );
      gsap.timeline().fromTo(
        element.querySelector(`#plate_03`),
        {
          x: 120,
        },
        {
          x: 0,
          scrollTrigger: {
            trigger: element.querySelector(`#planet`),
            start: "top bottom",
            end: "top center",
            scrub: 3,
            id: "scrub",
          },
        }
      );
      gsap.timeline().fromTo(
        element.querySelector(`#plate_04`),
        {
          x: -220,
        },
        {
          x: 0,
          scrollTrigger: {
            trigger: element.querySelector(`#planet`),
            start: "top bottom",
            end: "top center",
            scrub: 3,
            id: "scrub",
          },
        }
      );
      gsap.timeline().fromTo(
        element.querySelector(`#plate_05`),
        {
          x: 200,
        },
        {
          x: 0,
          scrollTrigger: {
            trigger: element.querySelector(`#planet`),
            start: "top bottom",
            end: "top center",
            scrub: 3,
            id: "scrub",
          },
        }
      );
      gsap.timeline().fromTo(
        element.querySelector(`#plate_06`),
        {
          x: -70,
        },
        {
          x: 0,
          scrollTrigger: {
            trigger: element.querySelector(`#planet`),
            start: "top bottom",
            end: "top center",
            scrub: 3,
            id: "scrub",
          },
        }
      );
    }
  }, []);

  return (
    <div className="container">
      <div className={classes.invincible} id="invincible" ref={ref}>
        <div className={[classes.header, "header-default"].join(" ")}>
          {t("quickstart_five__title")}
        </div>
        <div className={classes.content}>
          <img className={classes.planet} id="planet" src={planet} alt="" />
          <div
            id="plate_01"
            className={[classes.plate, classes.plate_01].join(" ")}
          >
            <Trans>{t("quickstart_five__item1")}</Trans>
          </div>
          <div
            id="plate_02"
            className={[classes.plate, classes.plate_02].join(" ")}
          >
            <Trans>{t("quickstart_five__item2")}</Trans>
          </div>
          <div
            id="plate_03"
            className={[classes.plate, classes.plate_03].join(" ")}
          >
            <Trans>{t("quickstart_five__item3")}</Trans>
          </div>
          <div
            id="plate_04"
            className={[classes.plate, classes.plate_04].join(" ")}
          >
            <Trans>{t("quickstart_five__item4")}</Trans>
          </div>
          <div
            id="plate_05"
            className={[classes.plate, classes.plate_05].join(" ")}
          >
            <Trans>{t("quickstart_five__item5")}</Trans>
          </div>
          <div
            id="plate_06"
            className={[classes.plate, classes.plate_06].join(" ")}
          >
            <Trans>{t("quickstart_five__item6")}</Trans>
          </div>
        </div>
        <div className={classes.btn}>
          <LightBtn />
        </div>
      </div>
    </div>
  );
};

export default Invincible;
