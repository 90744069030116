import React from "react";
import classes from "./ByInvesting.module.scss";
import stairs from "../../../assets/img/QuickStart/investing_stairs.png";
import LightBtn from "../../../UI/LightBtn/LightBtn";
import { useTranslation } from "react-i18next";
const ByInvesting = () => {
  const { t } = useTranslation();
  return (
    <div className="container">
      <div className={classes.byInvesting}>
        <div className={classes.content}>
          <div className={[classes.header, "header-default"].join(" ")}>
            {t("quickstart_three__title")}
          </div>
          <div className={classes.second}>
            {t("quickstart_three__subtitle")}
          </div>
          <div className={[classes.text, "header-default"].join(" ")}>
            {t("quickstart_three__text")}
          </div>
          <div className={classes.btn}>
            <LightBtn />
          </div>
        </div>
        <img className={classes.stairs} src={stairs} alt="" />
      </div>
    </div>
  );
};

export default ByInvesting;
