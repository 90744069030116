import React, { useState } from "react";
import Spoiler from "../../../UI/Spoiler/Spoiler";
import classes from "./Spoilers.module.scss";
const Spoilers = (props) => {
  const [spoilers, setSpoilers] = useState([
    {
      id: 1,
      title: "education_eight1__title",
      text: "education_eight1__text",
      active: false,
    },
    {
      id: 2,
      title: "education_eight2__title",
      text: "education_eight2__text",
      active: false,
    },
    {
      id: 3,
      title: "education_eight3__title",
      text: "education_eight3__text",
      active: false,
    },
    {
      id: 4,
      title: "education_eight4__title",
      text: "education_eight4__text",
      active: false,
    },
    {
      id: 5,
      title: "education_eight5__title",
      text: "education_eight5__text",
      active: false,
    },
    {
      id: 6,
      title: "education_eight6__title",
      text: "education_eight6__text",
      active: false,
    },
  ]);

  return (
    <div
      className={
        props.spoilers
          ? [classes.spoilers, classes.hover].join(" ")
          : classes.spoilers
      }
    >
      <div className={classes.spoilersBody}>
        <div className="container">
          <div className={classes.spoilersWrapper}>
            {spoilers.map((i, index) => {
              return (
                <Spoiler
                  {...i}
                  key={index}
                  clickEvent={() => {
                    setSpoilers(
                      spoilers.map((spoilerI) => {
                        if (spoilerI.id === i.id) {
                          return { ...spoilerI, active: !i.active };
                        }
                        return { ...spoilerI, active: false };
                      })
                    );
                  }}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Spoilers;
