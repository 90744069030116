import React from "react";
import classes from "./MakeMoney.module.scss";
import icon_01 from "../../../assets/img/QuickStart/make_money_01.png";
import icon_02 from "../../../assets/img/QuickStart/make_money_02.png";
import icon_03 from "../../../assets/img/QuickStart/make_money_03.png";
import icon_04 from "../../../assets/img/QuickStart/make_money_04.png";
import icon_05 from "../../../assets/img/QuickStart/make_money_05.png";
import LightBtn from "../../../UI/LightBtn/LightBtn";
import { Trans, useTranslation } from "react-i18next";

const MakeMoney = () => {
  const { t } = useTranslation();
  return (
    <div className="container">
      <div className={classes.makeMoney}>
        <div className={[classes.header, "header-default"].join(" ")}>
          {t("quickstart_four__title")}
        </div>
        <div className={classes.text}>{t("quickstart_four__subtitle")}</div>
        <div className={classes.cards}>
          <div className={classes.card}>
            <div className={classes.cardIcon}>
              <img src={icon_01} alt="" />
            </div>
            <div className={classes.cardText}>
              <Trans>{t("quickstart_four__item1")}</Trans>
            </div>
          </div>
          <div className={classes.card}>
            <div className={classes.cardIcon}>
              <img src={icon_02} alt="" />
            </div>
            <div className={classes.cardText}>
              <Trans>{t("quickstart_four__item2")}</Trans>
            </div>
          </div>
          <div className={classes.card}>
            <div className={classes.cardIcon}>
              <img src={icon_03} alt="" />
            </div>
            <div className={classes.cardText}>
              <Trans>{t("quickstart_four__item3")}</Trans>
            </div>
          </div>
          <div className={classes.card}>
            <div className={classes.cardIcon}>
              <img src={icon_04} alt="" />
            </div>
            <div className={classes.cardText}>
              <Trans>{t("quickstart_four__item4")}</Trans>
            </div>
          </div>
          <div className={classes.card}>
            <div className={classes.cardIcon}>
              <img src={icon_05} alt="" />
            </div>
            <div className={classes.cardText}>
              <Trans>{t("quickstart_four__item5")}</Trans>
            </div>
          </div>
        </div>
        <div className={classes.btn}>
          <LightBtn />
        </div>
      </div>
    </div>
  );
};

export default MakeMoney;
