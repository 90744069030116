import React from "react";
import classes from "./LightBtn.module.scss";
import { useTranslation } from "react-i18next";
const LightBtn = (props) => {
  const { t } = useTranslation();
  // eslint-disable-next-line
  if (props.black) {
    return (
      <div
        className={[classes.btn1, classes.head1, classes.black1].join(" ")}
        {...props}
      >
        <a href="https://account.evocrypto.tech/login">{props.text}</a>
      </div>
    );
  }
  if (props.send) {
    return (
      <div
        className={[classes.btn, classes.head1, classes.black1].join(" ")}
        {...props}
      >
        <span>{props.text}</span>
      </div>
    );
  }
  if (props.text || props.setClass) {
    return (
      <div
        className={[
          classes.btn,
          classes.head,
          props.setClass ? props.setClass : "",
        ].join(" ")}
        {...props}
      >
        <a href={props.href || "https://account.evocrypto.tech/login"}>{props.text}</a>
      </div>
    );
  }
  return (
    <div className={classes.btn} {...props}>
      <a href="https://account.evocrypto.tech/login">{t("btn_start")}</a>
    </div>
  );
};

export default LightBtn;
