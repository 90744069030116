import React, { useState } from "react";
import Have from "../../components/FaqPage/Have/Have";
import Support from "../../components/FaqPage/Support/Support";
import Footer from "../../components/GlobalComponents/Footer/Footer";
import Header from "../../components/GlobalComponents/Header/Header";
import classes from "./FaqPage.module.scss";

const FaqPage = () => {
  const [have, setHave] = useState(false);

  return (
    <div
      className={classes.faq}
      onScroll={() => {
        if (document.documentElement.clientWidth > 1220) {
          if (
            document.querySelector("#faq1").getBoundingClientRect().top < 400
          ) {
            setHave(true);
          } else {
            setHave(false);
          }
        }
      }}
    >
      <Header />
      <Support />
      <div id="faq1">
        <Have have={have} />
      </div>
      <Footer />
    </div>
  );
};

export default FaqPage;
