import React from "react";
import Header from "../../components/GlobalComponents/Header/Header";
import Banner from "../../components/QuickStart/Banner/Banner";
import ByInvesting from "../../components/QuickStart/ByInvesting/ByInvesting";
import ForexTrading from "../../components/QuickStart/ForexTrading/ForexTrading";
import Invincible from "../../components/QuickStart/Invincible/Invincible";
import MakeMoney from "../../components/QuickStart/MakeMoney/MakeMoney";
import QuickFacts from "../../components/QuickStart/QuickFacts/QuickFacts";
import Footer from "../../components/GlobalComponents/Footer/Footer";

const QuickStartPage = () => {
  return (
    <div>
      <Header />
      <Banner />
      <ForexTrading />
      <ByInvesting />
      <MakeMoney />
      <Invincible />
      <QuickFacts />
      <Footer />
    </div>
  );
};

export default QuickStartPage;
