import AboutPage from "../pages/AboutPage/AboutPage";
import EducationPage from "../pages/EducationPage/EducationPage";
import FaqPage from "../pages/FaqPage/FaqPage";
import MainPage from "../pages/MainPage/MainPage";
import QuickStartPage from "../pages/QuickStartPage/QuickStartPage";
import SuccessPage from "../pages/SuccessPage";
import FailPage from "../pages/FailPage";

export const routes = [
    { path: '/', element: MainPage },
    { path: '/faq', element: FaqPage },
    { path: '/about', element: AboutPage },
    { path: "/education", element: EducationPage },
    { path: "/quickstart", element: QuickStartPage },
    { path: "/pay-success", element: SuccessPage },
    { path: "/pay-fail", element: FailPage },
];

export const headerRoutes = [
    { id: 1, path: '/quickstart', text: 'header__item1' },
    { id: 2, path: '/education', text: 'header__item2' },
    { id: 3, path: '/about', text: 'header__item3' },
    { id: 4, path: '/faq', text: 'header__item4' },
];
