import React from "react";
import classes from "./AdvantagesItems.module.scss";
import img1 from "../../../assets/img/Main/earnning.gif";
import img2 from "../../../assets/img/Main/time.gif";
import img3 from "../../../assets/img/Main/coin.gif";
import img4 from "../../../assets/img/Main/device.gif";
import { Trans } from "react-i18next";
import { useTranslation } from "react-i18next";

const AdvantagesItems = () => {
  const { t } = useTranslation();
  return (
    <div className={classes.advantagesItemsContainer}>
      <div className={classes.advantagesItemsRow}>
        <div className={classes.col3}>
          <div className={classes.advantagesItemWrap}>
            <div className={classes.advantagesItemImgWrap}>
              <img src={img1} alt="" />
            </div>
            <p>
              <Trans>{t("main_second__item1")}</Trans>
            </p>
          </div>
        </div>
        <div className={classes.col3}>
          <div className={classes.advantagesItemWrap}>
            <div className={classes.advantagesItemImgWrap}>
              <img src={img2} alt="" />
            </div>
            <p>
              <Trans>{t("main_second__item2")}</Trans>
            </p>
          </div>
        </div>
        <div className={classes.col3}>
          <div className={classes.advantagesItemWrap}>
            <div className={classes.advantagesItemImgWrap}>
              <img src={img3} alt="" />
            </div>
            <p>
              <Trans>{t("main_second__item3")}</Trans>
            </p>
          </div>
        </div>
        <div className={classes.col3}>
          <div className={classes.advantagesItemWrap}>
            <div className={classes.advantagesItemImgWrap}>
              <img src={img4} alt="" />
            </div>
            <p>
              <Trans>{t("main_second__item4")}</Trans>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdvantagesItems;
