import React from "react";
import LightBtn from "../../../UI/LightBtn/LightBtn";
import classes from "./Benefits.module.scss";
import img1 from "../../../assets/img/education/3/2.png";
import { useTranslation } from "react-i18next";
const Benefits = (props) => {
  const { t } = useTranslation();
  return (
    <div
      className={
        props.benefits
          ? [classes.benefits, classes.hover].join(" ")
          : classes.benefits
      }
    >
      <div className={classes.benefitsBody}>
        <div className="container">
          <div className={classes.benefitsRow}>
            <div className={classes.benefitsRowLeft}>
              <div className={classes.benefitsRowLeftTitle}>
                {t("education_three_left__title")}
              </div>
              <div className={classes.benefitsRowLeftSubtitle}>
                {t("education_three_left__subtitle")}
              </div>
              <div className={classes.benefitsRowLeftList}>
                <p> {t("education_three_left__item1")}</p>
                <p> {t("education_three_left__item2")}</p>
                <p> {t("education_three_left__item3")}</p>
              </div>
              <div className={classes.benefitsStart}>
                <LightBtn />
              </div>
            </div>
            <div className={classes.benefitsRowRight}>
              <div className={classes.benefitsRowRightTitle}>
                {t("education_three_right__title")}
              </div>
              <div className={classes.benefitsRowRightSubtitle}>
                {t("education_three_right__subtitle1")}
              </div>
              <div className={classes.benefitsRowRightSubtitle}>
                {t("education_three_right__subtitle2")}
              </div>
            </div>
            <div className={classes.benefitsRowRight}>
              <div className={classes.benefitsRowRightTitle}>
                {t("education_three_left1__title")}
              </div>
              <div className={classes.benefitsRowRightSubtitle}>
                {t("education_three_left1__item1")}
              </div>
              <div className={classes.benefitsRowRightSubtitle}>
                {t("education_three_left1__item2")}
              </div>
            </div>
            <div
              className={[
                classes.benefitsRowImg,
                `benefitsRowImg_${t("lang")}`,
              ].join(" ")}
            >
              <img src={img1} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Benefits;
