import React from "react";
import classes from "./Our.module.scss";

import img1 from "../../../assets/img/education/4/1.png";
import img2 from "../../../assets/img/education/4/2.png";
import img3 from "../../../assets/img/education/4/3.png";
import img4 from "../../../assets/img/education/4/4.png";
import img5 from "../../../assets/img/education/4/5.png";
import img6 from "../../../assets/img/education/4/6.png";

import { Trans, useTranslation } from "react-i18next";

const Our = (props) => {
  const { t } = useTranslation();
  return (
    <div
      className={
        props.our ? [classes.our, classes.hover].join(" ") : classes.our
      }
    >
      <div className={classes.ourBody}>
        <div className="container">
          <div className={classes.ourTitle}>{t("education_four__title")}</div>
          <div className={classes.ourRow}>
            <div className={classes.ourRowColumn}>
              <div className={classes.ourRowColumnItem}>
                <div className={classes.ourRowColumnItemIcon}>
                  <img src={img1} alt="" />
                </div>
                <div
                  className={[
                    classes.ourRowColumnItemText,
                    `ourRowColumnItemText_${t("lang")}`,
                  ].join(" ")}
                >
                  <Trans>{t("education_four__item1")}</Trans>
                </div>
              </div>
            </div>
            <div className={classes.ourRowColumn}>
              <div className={classes.ourRowColumnItem}>
                <div className={classes.ourRowColumnItemIcon}>
                  <img src={img2} alt="" />
                </div>
                <div className={classes.ourRowColumnItemText}>
                  <Trans>{t("education_four__item2")}</Trans>
                </div>
              </div>
            </div>
            <div className={classes.ourRowColumn}>
              <div className={classes.ourRowColumnItem}>
                <div className={classes.ourRowColumnItemIcon}>
                  <img src={img3} alt="" />
                </div>
                <div className={classes.ourRowColumnItemText}>
                  <Trans>{t("education_four__item3")}</Trans>
                </div>
              </div>
            </div>
            <div className={classes.ourRowColumn}>
              <div className={classes.ourRowColumnItem}>
                <div className={classes.ourRowColumnItemIcon}>
                  <img src={img4} alt="" />
                </div>
                <div className={classes.ourRowColumnItemText}>
                  <Trans>{t("education_four__item4")}</Trans>
                </div>
              </div>
            </div>
            <div className={classes.ourRowColumn}>
              <div className={classes.ourRowColumnItem}>
                <div className={classes.ourRowColumnItemIcon}>
                  <img src={img5} alt="" />
                </div>
                <div
                  className={[
                    classes.ourRowColumnItemText,
                    `ourRowColumnItemText5_${t("lang")}`,
                  ].join(" ")}
                >
                  <Trans>{t("education_four__item5")}</Trans>
                </div>
              </div>
            </div>
            <div className={classes.ourRowColumn}>
              <div className={classes.ourRowColumnItem}>
                <div className={classes.ourRowColumnItemIcon}>
                  <img src={img6} alt="" />
                </div>
                <div className={classes.ourRowColumnItemText}>
                  <Trans>{t("education_four__item6")}</Trans>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Our;
