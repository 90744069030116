import React from "react";
import classes from './status.module.scss';
import image from '../assets/img/fail.svg';

const FailPage = () => {
    return (
        <div className={classes.wrap}>
            <div className={classes.content}>
                <img className={classes.image} src={image} alt='' />
                <p className="container">
                    Payment<br/><b>failed</b>
                </p>
            </div>
        </div>
    );
};

export default FailPage;
