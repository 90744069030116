import React, {useEffect} from "react";
import * as THREE from 'three';
import gsap from "gsap";
import Surface from "./Surface"
import earthMapImg from "../../../../assets/img/Main/earth-map.jpeg"

const Planet = () => {
  useEffect(() => {
    const demo = document.querySelector('.demo');

    gsap.set(demo, {
      height: 360
    });

    let surface = new Surface();

    new THREE.TextureLoader().load(
      earthMapImg,
      (mapTex) => {
        surface.earthTexture = mapTex;
        surface.earthTexture.repeat.set(1, 1);
        surface.earthTextureData = surface.getImageData();
        surface.createGlobe();
        surface.addAnchor();
        surface.addCanvasEvents();
        surface.updateDotSize();
        surface.loop();
      });

    window.addEventListener('resize', () => {
      gsap.set(demo, {
        // height: window.innerHeight
      });

      surface.updateSize();
      surface.setupOverlayGraphic();
      surface.updateDotSize();
      surface.addCanvasEvents();
    }, false);


  }, []);

  return (
    <div className="demo">
      <div className="animation-wrapper">
        <canvas id="globe-3d"></canvas>
        <canvas id="globe-2d-overlay"></canvas>
        <div id="globe-popup-overlay">
          <div className="globe-popup"></div>
        </div>
      </div>
    </div>
  )
}

export default Planet;
