import React from "react";
import img1 from "../../../assets/img/about/1/1.png";
import LightBtn from "../../../UI/LightBtn/LightBtn";
import classes from "./About.module.scss";
import { useTranslation } from "react-i18next";
const About = () => {
  const { t } = useTranslation();

  return (
    <div className={classes.about}>
      <div className={classes.aboutBody}>
        <div className="container">
          <div className={classes.aboutRow}>
            <div className={classes.aboutRowLeft}>
              <div className={classes.aboutTitle}>
                {t("education_six__title")}
              </div>
              <div className={classes.aboutSubtitle}>
                {t("education_six__subtitle")}
              </div>
              <div className={classes.aboutStart}>
                <LightBtn />
              </div>
            </div>
            <div className={classes.aboutRowRight}>
              <img src={img1} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
