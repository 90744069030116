import React from "react";
import classes from "./TradingPlatform.module.scss";
import LightBtn from "../../../UI/LightBtn/LightBtn";
import img from "../../../assets/img/Main/trade-platform-img.png";
import { useTranslation } from "react-i18next";
const TradingPlatform = () => {
  const { t } = useTranslation();
  return (
    <div className="container">
      <h2 className={classes.tradingPlatformTitle}>{t("main_four__title")}</h2>
      <div className={classes.tradingPlatformRow}>
        <div className={classes.tradingPlatformCol6}>
          <div className={classes.tradingPlatformList}>
            <div className={classes.tradingPlatformItem}>
              <h6 className={classes.tradingPlatformItemTitle}>
                {t("main_four_item1__title")}
              </h6>
              <p className={classes.tradingPlatformItemText}>
                {t("main_four_item1__subtitle")}
              </p>
            </div>
            <div className={classes.tradingPlatformItem}>
              <h6 className={classes.tradingPlatformItemTitle}>
                {t("main_four_item2__title")}
              </h6>
              <p className={classes.tradingPlatformItemText}>
                {t("main_four_item2__subtitle")}
              </p>
            </div>
            <div className={classes.tradingPlatformItem}>
              <h6 className={classes.tradingPlatformItemTitle}>
                {t("main_four_item3__title")}
              </h6>
              <p className={classes.tradingPlatformItemText}>
                {t("main_four_item3__subtitle")}
              </p>
            </div>
            <div className={classes.tradingPlatformItem}>
              <h6 className={classes.tradingPlatformItemTitle}>
                {t("main_four_item4__title")}
              </h6>
              <p className={classes.tradingPlatformItemText}>
                {t("main_four_item4__subtitle")}
              </p>
            </div>
            <div className={classes.tradingPlatformItem}>
              <h6 className={classes.tradingPlatformItemTitle}>
                {t("main_four_item5__title")}
              </h6>
              <p className={classes.tradingPlatformItemText}>
                {t("main_four_item5__subtitle")}
              </p>
            </div>
            <div className={classes.tradingPlatformItem}>
              <h6 className={classes.tradingPlatformItemTitle}>
                {t("main_four_item6__title")}
              </h6>
              <p className={classes.tradingPlatformItemText}>
                {t("main_four_item6__subtitle")}
              </p>
            </div>
          </div>
          <div className={classes.tradingPlatformLinkWrap}>
            <LightBtn />
          </div>
        </div>
        <div
          className={[
            classes.tradingPlatformCol6,
            classes.tradingPlatformImgWrap,
          ].join(" ")}
        >
          <img src={img} alt="" className={classes.imgFluid} />
        </div>
      </div>
    </div>
  );
};

export default TradingPlatform;
