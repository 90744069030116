import React, { useState } from "react";
import LightBtn from "../../../UI/LightBtn/LightBtn";
import classes from "./Have.module.scss";
import { useTranslation } from "react-i18next";

const Have = (props) => {
  const [check, setCheck] = useState(false);
  const { t } = useTranslation();
  return (
    <div
      className={
        props.have ? [classes.have, classes.hover].join(" ") : classes.have
      }
    >
      <div className={classes.haveBody}>
        <div className="container">
          <div className={classes.haveForm}>
            <div className={classes.haveFormTitle}>{t("main_six__title")}</div>
            <div className={classes.haveFormSubtitle}>
              {t("main_six__subtitle")}
            </div>
            <div className={classes.haveFormInput}>
              <input type="text" placeholder={t("main_six_form__name")} />
            </div>
            <div className={classes.haveFormInput}>
              <input type="text" placeholder={t("main_six_form__phone")} />
            </div>
            <div className={classes.haveFormInput}>
              <input type="text" placeholder={t("main_six_form__email")} />
            </div>
            <div className={classes.haveFormTextarea}>
              <textarea placeholder={t("main_six_form__question")}></textarea>
            </div>
            <div
              className={
                check
                  ? [classes.haveFormCheckbox, classes.active].join(" ")
                  : classes.haveFormCheckbox
              }
              onClick={() => {
                setCheck(!check);
              }}
            >
              <span></span>
              {t("main_six_form__checkbox")}
            </div>
            <div className={classes.haveFormSubmit}>
              <LightBtn text={t("btn_submit")} send={"1"} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Have;
