import React from "react";
import classes from "./Banner.module.scss";
import LightBtn from "../../../UI/LightBtn/LightBtn";
import Planet from "./PlanetAnimation/Planet";
import { useTranslation } from "react-i18next";
const Banner = () => {
  const { t } = useTranslation();
  return (
    <div className="container">
      <div className={classes.bannerRow}>
        <div className={classes.col6}>
          <h1
            className={[classes.bannerTitle, `bannerTitle_${t("lang")}`].join(
              " "
            )}
          >
            {t("main_first__title")}
          </h1>
          <p className={classes.bannerText}>{t("main_first__subtitle")}</p>
          <div className={classes.bannerLinkWrap}>
            <LightBtn />
          </div>
        </div>
        <div className={[classes.col6, classes.planetWrap].join(' ')}>
          <Planet />
        </div>
      </div>
    </div>
  );
};

export default Banner;
